import React from 'react';

import './LangToggle.css';

const LangToggle = React.memo(({onValueChange, value}) => {

    return (
        <div className={`${value === 'es' ? 'left' : 'right'} toggle mr-2 font-light`}>
            <button
                onClick={onValueChange}
                className={`${value === 'es' ? 'text-white' : ''} es outline-none`}>ES</button>
            <button
                onClick={onValueChange}
                className={`${value === 'en' ? 'text-white' : ''} en outline-none`}>EN</button>
        </div>
    );
});

export default LangToggle;