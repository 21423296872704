const strings = {
    es: {
        components: {
            horizontalSlideshow: {
                slideNextImageLabel: 'Siguiente',
                slidePreviousImageLabel: 'Anterior',
            }
        },
        generic: {
            eventRequest: "Cotiza el servicio aquí"
        },
        home: {
            title: 'Collection Events',
            description: 'Somos una Empresa de Consultoría y Producción de Eventos donde Colaboramos con Usted para desarrollar soluciones Integrales, Creativas y Personalizadas que satisfagan las necesidades Audiovisuales (Audio Video e Iluminación), Planeación, Organización, Logística, Coordinación y Supervisión de Eventos Empresariales, Sociales y Especiales, de lo simple a los mas sofisticado.',
            welcome: 'Bienvenido'
        },
        about: {
            menuText: 'Acerca',
            title: 'Quiénes somos',
            description: 'Somos una empresa de consultoria y producción de eventos que acompaña a sus clientes para desarrollar, crear y personalizar soluciones que satisfagan todas las necesidades de sus eventos.',
            paragraphs: [
                `<p>Somos una empresa de consultoria y producción de eventos que acompaña a sus clientes para
                desarrollar, crear y personalizar soluciones que satisfagan todas las necesidades de sus eventos.
                Tenemos experiencia en Planeación, Organización, Logistica, Coordinación, Supervisión y Servicios
                Audiovisuales para Eventos Coorporativos y Sociales, Bodas, Eventos Especiales y Conciertos.
                ¡Vamos desde lo más sencillo hasta lo más sofisticado!</p>`,
                `<p>Gracias a nuestros 15 años de experiencia, somos <strong>representantes</strong> de Destino para <strong>Organizadores
                de Bodas, Eventos y Reuniones a nivel nacional e internacional.</strong>
                Trabajamos de la mano representando a la cadena de valor de proveedores locales para cubrir
                cada pequeño detalle de los eventos que realizamos. 
                Gracias a nuestra alianza con nuestra empresa hermana <strong>Audio Visual (AV-Services Cabo)</strong>,
                podemos garantizar un servicio integral y el equipo más especializado para diferentes tipos de
                presentaciones.</p>`,
                `<p>Nuestas oficinas se ubican en Los Cabos, Cancún, Rivera Maya, Guadalajara, Ciudad de Mèxico y
                Hermosillo.</p>`
            ]
        },
        weddings: {
            menuText: 'Bodas',
            title: 'Bodas',
            description: 'Somos una Empresa de Consultoría y Producción de Eventos donde Colaboramos con Usted para desarrollar soluciones Integrales, Creativas y Personalizadas que satisfagan las necesidades Audiovisuales (Audio Video e Iluminación), Planeación, Organización, Logística, Coordinación y Supervisión de Eventos Empresariales, Sociales y Especiales, de lo simple a los mas sofisticado.',
            listItems: [
                'Ceremonias simbolicas',
                'Legales',
                'Judías',
                'Igualitarias',
            ]
        },
        events: {
            menuText: 'Eventos',
            menuSubitems: [
                {
                    url: `/[locale]/events#corporate`,
                    text: 'Corporativos'
                },
                {
                    url: `/[locale]/events#tradeshows`,
                    text: 'Trade shows'
                },
                {
                    url: `/[locale]/events#products`,
                    text: 'Lanzamiento de productos'
                },
                {
                    url: `/[locale]/events#design`,
                    text: 'Diseño y producción'
                },
            ],
            title: 'Eventos',
            description: 'Creamos y producimos tu idea.',
            eventTypes: [
                {
                    hash: '#corporate',
                    title: 'Corporativo',
                    text: `Congresos, Convenciones e Incentivos
                    Nos encargamos de todas las cuestiones que llevan al excelente desempeño de un
                    Congreso o Convención a fin de que su personal, agremiados e invitados, vivan una
                    experiencia edificante y emocionante.
                    Y cuando se trata de demostrar al personal lo mucho que valora su esfuerzo, un Viaje de
                    Incentivo es lo mejor, por eso le ofrecemos toda nuestra experiencia para crear momentos
                    únicos que se reflejarán en beneficios para su empresa.`,
                    list: []
                },
                {
                    hash: '#tradeshows',
                    title: 'Trade shows',
                    text: `Ferias y Exposiciones
                    Nos especializamos en todo lo referente a la realización de sus exposiciones, brindando
                    una amplia gama de opciones apropiadas para cumplir sus deseos.`,
                    list: [
                        'Galerías y Recintos de Exposiciones',
                        'Diseño y Montaje de Stands',
                        'Equipamiento y Accesorios',
                        'Logística'
                    ]
                },
                {
                    hash: '#products',
                    title: 'Lanzamiento de la marca',
                    text: `El lanzamiento de un servicio o producto es una estrategia clave en el desempeño
                    competitivo de los negocios de hoy. Por ello, contar con una correcta planeación es tan
                    importante como realizar una brillante ejecución.
                    Nuestra experiencia en la planificación y logística de grandes eventos corporativos es la
                    clave para saber controlar el lanzamiento de sus productos.
                    Para lograr sus objetivos usaremos todas las herramientas promocionales y de producción
                    que tenemos disponibles, alcanzando con ello el máximo impacto.`,
                    list: []
                },
                {
                    hash: '#design',
                    title: 'Diseño y producción',
                    text: `Creamos y producimos tu idea.`,
                    list: []
                },
            ]
        },
        services: {
            menuText: 'Servicios',
            menuSubitems: [
                {
                    url: `/[locale]/services#furnishings`,
                    text: 'Mobiliario'
                },
                {
                    url: `/[locale]/services#audiovisual`,
                    text: 'Audiovisual'
                },
                {
                    url: `/[locale]/services#flowers`,
                    text: 'Floreria'
                },
                {
                    url: `/[locale]/services#catering`,
                    text: 'Banquetes'
                },
                {
                    url: `/[locale]/services#lodging`,
                    text: 'Hospedaje'
                },
                {
                    url: `/[locale]/services#transportation`,
                    text: 'Transportación'
                },
                {
                    url: `/[locale]/services#makeup`,
                    text: 'Make up'
                },
                {
                    url: `/[locale]/services#staff`,
                    text: 'Staff'
                },
                {
                    url: `/[locale]/services#souvenirs`,
                    text: 'Souvenirs'
                },
            ],
            title: 'Servicios',
            description: 'Somos una Empresa de Consultoría y Producción de Eventos donde Colaboramos con Usted para desarrollar soluciones Integrales, Creativas y Personalizadas que satisfagan las necesidades Audiovisuales (Audio Video e Iluminación), Planeación, Organización, Logística, Coordinación y Supervisión de Eventos Empresariales, Sociales y Especiales, de lo simple a los mas sofisticado.',
            services: [
                { hash: '#furnishings', title: 'Mobiliario' },
                { hash: '#audiovisual', title: 'Audiovisual' },
                { hash: '#flowers', title: 'Florería' },
                { hash: '#catering', title: 'Banquetes' },
                { hash: '#lodging', title: 'Hospedaje' },
                { hash: '#transportation', title: 'Transportación' },
                { hash: '#makeup', title: 'Make up' },
                { hash: '#staff', title: 'Staff' },
                { hash: '#souvenirs', title: 'Souvenirs' }
            ]
        },
        venues: {
            menuText: 'Venues',
            title: 'Venues',
            description: 'Somos una Empresa de Consultoría y Producción de Eventos donde Colaboramos con Usted para desarrollar soluciones Integrales, Creativas y Personalizadas que satisfagan las necesidades Audiovisuales (Audio Video e Iluminación), Planeación, Organización, Logística, Coordinación y Supervisión de Eventos Empresariales, Sociales y Especiales, de lo simple a los mas sofisticado.',
            items: [
                { hash: '#beach', title: 'Playa' },
                { hash: '#garden', title: 'Jardin' },
                { hash: '#terrace', title: 'Terraza' },
                { hash: '#meetings', title: 'Salones de eventos y reuniones' },
            ]
        },
        contact: {
            title: 'Contacto',
            description: 'Collection Events México. Contáctanos hoy.',
            menuText: 'Contacto',
            emailText: 'Correo electrónico',
            presenceIn: 'Presencia en',
            phones: 'Télefonos',
            followUs: 'Siguenos en',
            formFields: {
                name: 'Nombre completo',
                commercialName: 'Alias / Nombre comercial',
                phone: 'Telefono / Celular',
                email: 'Correo electrónico',
                service: 'Servicio requerido',
                date: 'Fecha de evento',
                people: 'Cantidad de personas',
                budget: 'Presupuesto estimado',
                place: 'Ubicación',
                about: 'Platicanos un poco acerca del evento',
                submitButton: 'Enviar formulario',
                successMsg: 'Gracias! Hemos recibido tu correo, en breve nos pondremos en contacto contigo.',
            }
        },
        footer: {
            copyright: '© 2021 Collection Events. Todos los derechos reservados.',
            privacyPolicy: 'Términos y condiciones | Aviso de privacidad'
        }
    },
    en: {
        components: {
            horizontalSlideshow: {
                slideNextImageLabel: 'Next',
                slidePreviousImageLabel: 'Previous',
            }
        },
        generic: {
            eventRequest: "Event request"
        },
        home: {
            title: 'Collection Events',
            description: 'Event management company that provides complete Planning, Organization and Logistic for Corporate, Social and Special Events.',
            welcome: 'Welcome'
        },
        about: {
            menuText: 'About',
            title: 'About us',
            description: 'We are a consulting and events production company that collaborate with you to develop, create and customize solutions to satisfy all your event’s needs.',
            paragraphs: [
                `<p>We are a consulting and events production company that collaborate with you to develop, create
                and customize solutions to satisfy all your event’s needs. 
                We provide expertise in Planning, Organization, Logistics, Coordination, Supervision, and
                Audiovisual Services for Corporate and Social Events, Weddings, Special Events, and Concerts.
                We make it from the simplest to the most sophisticated!</p>`,
                `<p>We have 15 years of experience, what has made us <strong>representatives</strong> of the Destination for
                <strong>Weedings, Meetings, and Events Planners, both nationally and internationally.</strong>
                We do team effort and represent the local suppliers’ value chain to fulfill all the goals of the events
                we carry out.</p>`,
                `<p>Due to the alliance we have with our sister company <strong>Audio Visual (AV-Services Cabo)</strong>, we can
                ensure comprehensive attention with quality service and cutting-edge systems for every single
                presentation.</p>`,
                `<p>Our offices are located in Los Cabos, Cancun, Rivera Maya, Guadalajara, Mexico City and
                Hermosillo.</p>`
            ]
        },
        weddings: {
            menuText: 'Weddings',
            title: 'Weddings',
            description: 'Event management company that provides complete Planning, Organization and Logistic for Corporate, Social and Special Events.',
            listItems: [
                'Symbolic wedding ceremony',
                'Legal marriage',
                'Jewish wedding',
                'LGTB wedding',
            ]
        },
        events: {
            menuText: 'Events',
            menuSubitems: [
                {
                    url: `/[locale]/events#corporate`,
                    text: 'Corporate'
                },
                {
                    url: `/[locale]/events#tradeshows`,
                    text: 'Trade shows'
                },
                {
                    url: `/[locale]/events#products`,
                    text: 'Branch launch'
                },
                {
                    url: `/[locale]/events#design`,
                    text: 'Design & production'
                },
            ],
            title: 'Events',
            description: 'We create and produce your idea.',
            eventTypes: [
                {
                    hash: '#corporate',
                    title: 'Corporate',
                    text: `Congresses, Conventions and Incentives
                    As we want you, your staff, members, and guests to live an edifiying and exciting
                    experience during a Congress or Convention, we’ll manage everything to make it happend.
                    And when it comes to valuing the work of your staff, an incentive trip is the best way. Our
                    commitment is to contribute with all our experience to create unmatched moments that
                    will increase your company’s success.`,
                    list: []
                },
                {
                    hash: '#tradeshows',
                    title: 'Trade shows',
                    text: `Fairs & Exposition
                    We are the specialists you deserv for your expos, providing a broad range of suitable
                    options to make your wishes come true.`,
                    list: [
                        'Galleries & Exhibition Venues',
                        'Stand Design & Assembly',
                        'Equiptment & Accessories',
                        'Logistics'
                    ]
                },
                {
                    hash: '#products',
                    title: 'Brand launch',
                    text: `Brand launch is a process of creating a fundamental strategy for your company to have a
                    competitive performance nowadays. Consequently, it is essencial to make a proper
                    planning and a magnificent execution.
                    With our expertise in planning and logistics for large corporate events, we can give a
                    guarantee on a perfect product launching.
                    We will use all the promotional and production tools we have to achieve your goals,
                    reaching the greatest impact on the market.`,
                    list: []
                },
                {
                    hash: '#design',
                    title: 'Design & production',
                    text: `We create and produce your idea.`,
                    list: []
                },
            ]
        },
        services: {
            menuText: 'Services',
            menuSubitems: [
                {
                    url: `/[locale]/services#furniture`,
                    text: 'Rental equipment'
                },
                {
                    url: `/[locale]/services#audiovisual`,
                    text: 'Audiovisual'
                },
                {
                    url: `/[locale]/services#flowers`,
                    text: 'Floral arrangements'
                },
                {
                    url: `/[locale]/services#catering`,
                    text: 'Catering'
                },
                {
                    url: `/[locale]/services#lodging`,
                    text: 'Lodging'
                },
                {
                    url: `/[locale]/services#transportation`,
                    text: 'Transportation'
                },
                {
                    url: `/[locale]/services#makeup`,
                    text: 'Make up'
                },
                {
                    url: `/[locale]/services#staff`,
                    text: 'Staff'
                },
                {
                    url: `/[locale]/services#souvenirs`,
                    text: 'Souvenirs'
                },
                {
                    url: `/[locale]/services#furnishings`,
                    text: 'Furnishings'
                },
            ],
            title: 'Services',
            description: 'Event management company that provides complete Planning, Organization and Logistic for Corporate, Social and Special Events.',
            services: [
                { hash: '#furnishings', title: 'Furnishings' },
                { hash: '#audiovisual', title: 'Audiovisual' },
                { hash: '#flowers', title: 'Floral arrangements' },
                { hash: '#catering', title: 'Catering' },
                { hash: '#lodging', title: 'Lodging' },
                { hash: '#transportation', title: 'Transportation' },
                { hash: '#makeup', title: 'Make up' },
                { hash: '#staff', title: 'Staff' },
                { hash: '#souvenirs', title: 'Souvenirs' }
            ]
        },
        venues: {
            menuText: 'Venues',
            title: 'Venues',
            description: 'Event management company that provides complete Planning, Organization and Logistic for Corporate, Social and Special Events.',
            items: [
                { hash: '#beach', title: 'Beach' },
                { hash: '#garden', title: 'Garden' },
                { hash: '#terrace', title: 'Terrace' },
                { hash: '#meetings', title: 'Meetings & events rooms' },
            ]
        },
        contact: {
            title: 'Contact',
            description: 'Collection Events México. Contact us now.',
            menuText: 'Contact',
            emailText: 'Email',
            presenceIn: 'Presence in',
            phones: 'Phones',
            followUs: 'Follow us',
            formFields: {
                name: 'Name',
                commercialName: 'Alias / Commercial name',
                phone: 'Phone / Cellphone',
                email: 'Email',
                service: 'Service',
                date: 'Date',
                people: 'Amount of people',
                budget: 'Estimated budget',
                place: 'Location',
                about: 'Tell us a little about the event',
                submitButton: 'Send form',
                successMsg: 'Thank you! We have received your email, we will contact you shortly.'
            }
        },
        footer: {
            copyright: '© 2021 all rights reserved by Collection Events.',
            privacyPolicy: 'Terms & conditions | Privacy policy'
        }
    }
};

export default strings;