import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isValidLocale, defaultLocale, getInitialLocale } from '../translations';

export const LocaleContext = createContext({});

export const LocaleProvider = ({ children }) => {
    const [ locale, setLocale ] = useState(defaultLocale);
    const location = useLocation();

    // Set locale for the first time
    useEffect(() => {
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', getInitialLocale());
        }
    }, []);

    // Detects when query lang params changed
    useEffect(() => {
        const routeSplited = location.pathname.split('/');
        const localeFromParams = routeSplited[1];
        if (isValidLocale(localeFromParams) && localeFromParams !== locale) {
            setLocale(localeFromParams);
            localStorage.setItem('locale', localeFromParams);
        }
    }, [ location.pathname ])

    return <LocaleContext.Provider value={{locale, setLocale}}>{children}</LocaleContext.Provider>
};