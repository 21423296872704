import React, { useContext } from 'react';
import { Slide } from 'react-slideshow-image';
import Image1 from '../../assets/img/marriage.jpg';
import Image2 from '../../assets/img/glasses.jpg';
import Image3 from '../../assets/img/wedding.jpg';
import FiguraBg from '../../assets/img/figura_ce.png';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';
import { Helmet } from 'react-helmet';

import {
    HorizontalSlideshow
} from '../../components';

const About = () => {
    const { locale } = useContext(LocaleContext);

    return (
        <div
            className="w-screen min-h-screen flex gap-8 flex flex-col md:flex-row relative">
            <Helmet>
                <title>{Strings[locale].about.title}</title>
                <meta name="description" content={Strings[locale].about.description} />
            </Helmet>
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:w-1/6 px-6">
                <h4 className="text-xl lg:text-xl uppercase font-bold text-gray-900">{Strings[locale].about.title}</h4>
                <p className="text-xs mt-2">
                    {Strings[locale].about.paragraphs.map((item, index) => <div key={`about${index}`} className="mb-4"><div dangerouslySetInnerHTML={{__html: item}} /></div>)} 
                </p>
            </div>
            <div className="w-full md:w-3/4 xl:5/6 h-auto mb-0">
                <div className="slide-container md:hidden">
                    <Slide>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image1})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image2})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image3})`}} className="h-64 bg-cover" />
                        </div>
                    </Slide>
                </div>
                <div className="hidden md:block">
                    <HorizontalSlideshow />
                </div>
            </div>
        </div>
    );
};

export default About;