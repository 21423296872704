import { locales, defaultLocale } from './config';

export function isValidLocale(localeToTest) {
    return locales.some(item => item === localeToTest);
}

export function getInitialLocale() {
    const localeSetting = localStorage.getItem('locale');

    if (localeSetting && isValidLocale(localeSetting)) {
        return localeSetting;
    }

    const [ browserLocale ] = navigator.language.split('-');
    if (isValidLocale(browserLocale)) {
        return browserLocale;
    }

    return defaultLocale;
};