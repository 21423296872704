import React, { useState, useCallback, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import WhiteLogo from '../../assets/img/whitelogo.png';
import Logo from '../../assets/img/logo.png';
import Background from '../../assets/img/bg.jpg';
import Strings from '../../translations/strings';

import {
    MobileNavbar,
    LangToggle
} from '../../components';

const Layout = React.memo(({children, location, history}) => {
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const routeSplited = location.pathname.split('/');
    const locale = routeSplited[1];
    const currentSection = routeSplited[2];
    const [ marginTop, setMarginTop ] = useState(0);
    
    const onLangToggleChange = useCallback(() => {
        // console.log(`${location.pathname}${location.hash}`.replace(locale, locale === 'es' ? 'en' : 'es')); 
        history.push(`${location.pathname}${location.hash}`.replace(locale, locale === 'es' ? 'en' : 'es')); 
    }, [locale, location, history]);

    const getLogo = () => {
        return !currentSection
        ?
            <Link to={`/${locale}`}>
                <img
                    src={WhiteLogo}
                    alt="Logo"
                    width={236}
                    height={90} />
            </Link>
        :
            <Link to={`/${locale}`}>
                <img
                    src={Logo}
                    alt="White logo"
                    width={236}
                    height={90} />
            </Link>;
    }

    const onMenuItemClick = useCallback((url) => {
        setIsMenuOpen((st) => !st)
        history.push(url);
    }, [setIsMenuOpen, history]);

    useEffect(() => {
        setIsMenuOpen(false);
        if (currentSection) {
            setTimeout(() => {
                const el = document.querySelector(window.innerWidth <= 768 ? '.mobile-header' : '.desktop-header');
                const styles = window.getComputedStyle(el);
                setMarginTop(parseInt(styles.height, 10) + parseInt(styles.marginTop, 10) + 10);
            }, 400);
        } else {
            setMarginTop(0);
        }
    }, [currentSection])

    useEffect(() => {
        if (currentSection) {
            const el = document.querySelector(window.innerWidth <= 768 ? '.mobile-header' : '.desktop-header');
            const styles = window.getComputedStyle(el);
            setMarginTop(parseInt(styles.height, 10) + parseInt(styles.marginTop, 10) + 10);
        }
        
        window.onresize =() => {
            if (currentSection) {
                const el = document.querySelector(window.innerWidth <= 768 ? '.mobile-header' : '.desktop-header');
                const styles = window.getComputedStyle(el);
                setMarginTop(parseInt(styles.height, 10) + parseInt(styles.marginTop, 10) + 10);
            }
        };
        // eslint-disable-next-line
    }, []);
    
    return  (
        <>
            {currentSection &&
                <div className={`w-full flex flex-col md:flex-row justify-between items-center px-6 py-2 bg-white fixed gap-2 md:gap-0 z-70`}>
                    <div className="flex gap-2">
                        <a href="https://www.facebook.com/CollectionEventsMexico" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} className="cursor-pointer" />
                        </a>
                        
                        <a href="https://www.instagram.com/collectioneventsmexico/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="cursor-pointer" />
                        </a>
                    </div>
                    <div className="flex flex-col xs:flex-row gap-2 xs:gap-4">
                        <a
                            href="mailto:contact@collectioneventmexico.com"
                            target="_blank"
                            rel="noreferrer"
                            className="gap-2 flex items-center">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>contact@collectioneventmexico.com</span>
                        </a>
                        <a
                            href="tel:+52 (662) 194 5373"
                            target="_blank"
                            rel="noreferrer"
                            className="gap-2 flex items-center">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>+52 (662) 194 5373</span>
                        </a>
                    </div>
                </div>}
            {/* Mobile menu */}
            {isMenuOpen && <MobileNavbar locale={locale} setIsMenuOpen={setIsMenuOpen} onMenuItemClick={onMenuItemClick} currentSection={currentSection} onLangToggleChange={onLangToggleChange} />}
            <div
                style={{backgroundImage: `url('${Background}')`}}
                className="w-full h-full bg-cover overflow-x-hidden font-nunito">
                <div className={`${currentSection ? 'flex-col mt-24 xs:mt-20 sm:mt-16' : 'flex-row'} mobile-header flex md:hidden justify-center items-center transition-all ease-in-out duration-500 fixed z-40 w-full py-2 px-6 bg-white`}>
                    <Link
                        to={`/${locale}`}>
                        <img
                            src={Logo}
                            alt="logo"
                            width={236}
                            height={90} />
                    </Link>
                    <div className={`${currentSection ? 'mt-4' : ''} justify-between w-full flex items-center`}>
                        <div className={`text-4xl uppercase section-title flex items-center text-green-700`}>
                            <span className="ml-2 font-caslon">{locale && currentSection && Strings[locale] && Strings[locale][currentSection]?.menuText}</span>
                        </div>
                        <div className={`${currentSection === '[lang]' && !isMenuOpen ? 'text-white' : 'text-green-700'}`}>
                            <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} />
                        </div>
                    </div>
                </div>
                <nav className={`${isMenuOpen ? 'text-black' : 'text-white'} ${currentSection || isMenuOpen ? 'bg-white' : ''} desktop-header hidden md:flex justify-center items-center transition-all ease-in-out duration-500 fixed z-40 w-full ${currentSection ? 'mt-10' : 'py-6'} px-8`}>
                    <div className={`${!currentSection || isMenuOpen ? 'invisible' : ''} text-4xl uppercase section-title flex items-center text-green-700 absolute left-0`}>
                        <div className="w-4 ml-2">
                            <div className="w-full bg-green-700 mb-2" style={{height: '2px'}}></div>
                            <div className="w-1/2 bg-green-700 mb-2 ml-2" style={{height: '2px'}}></div>
                            <div className="w-full bg-green-700" style={{height: '2px'}}></div>
                        </div>
                        <span className="ml-2 font-caslon">{locale && currentSection && Strings[locale] && Strings[locale][currentSection]?.menuText}</span>
                    </div>
                    {isMenuOpen ? <ul className="text-xs sm:text-sm lg:text-lg font-extralight uppercase flex">
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center" onClick={() => onMenuItemClick(`/${locale}/about`)}>
                            {Strings[locale]?.about.menuText}
                        </li>
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center" onClick={() => onMenuItemClick(`/${locale}/weddings`)}>
                            {Strings[locale]?.weddings.menuText}
                        </li>
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center relative hover-trigger">
                            <p onClick={() => onMenuItemClick(`/${locale}/events#corporate`)}>{Strings[locale]?.events.menuText}</p>
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2 text-sm" />
                            <ul className="bg-white absolute left-6 hover-target text-black" style={{top: '100%'}}>
                                {Strings[locale].events.menuSubitems.map((item, index) =>
                                    <li key={`event${index}`} className="pl-4 pr-12 py-2 hover:text-green-500" onClick={() => onMenuItemClick(item.url.replace('[locale]', locale))}>
                                        {item.text}
                                    </li>)}
                            </ul>
                        </li>
                        <li className="md:px-1 lg:px-4 cursor-pointer">
                            <Link to={`/${locale}`}>
                                <img
                                    src={Logo}
                                    alt="White logo"
                                    width={236}
                                    height={90} />
                            </Link>
                        </li>
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center hover-trigger">
                            <p onClick={() => onMenuItemClick(`/${locale}/services`)}>{Strings[locale]?.services.menuText}</p>
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2 text-sm" />
                            <ul className="bg-white absolute left-6 hover-target text-black" style={{top: '80%'}}>
                                {Strings[locale].services.menuSubitems.map((item, index) =>
                                    <li key={`service${index}`} className="pl-4 pr-12 py-2 hover:text-green-500" onClick={() => onMenuItemClick(item.url.replace('[locale]', locale))}>
                                        {item.text}
                                    </li>)}
                            </ul>
                        </li>
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center" onClick={() => onMenuItemClick(`/${locale}/venues`)}>
                            {Strings[locale]?.venues.menuText}
                        </li>
                        <li className="px-1 sm:px-3 lg:px-6 hover:text-green-500 cursor-pointer flex justify-center items-center" onClick={() => onMenuItemClick(`/${locale}/contact`)}>
                            {Strings[locale]?.contact.menuText}
                        </li>
                        </ul>
                        : getLogo()
                    }
                    <div className={`${!currentSection && !isMenuOpen ? 'text-white' : 'text-green-700'} absolute right-0 mr-4 flex items-center`}>
                        {!isMenuOpen && <LangToggle value={locale} onValueChange={onLangToggleChange} />}
                        <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} />
                    </div>
                </nav>

                <main style={{ marginTop: `${marginTop}px` }}>
                    {children}
                </main>
            </div>
            {currentSection && <footer className="bg-primary py-4 px-6 text-white flex flex-col md:flex-row justify-between items-center text-center">
                <p>{Strings[locale].footer.copyright}</p>
                <p>{Strings[locale].footer.privacyPolicy}</p>
            </footer>}
        </>
    );
});

export default withRouter(Layout);