import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';
import './Welcome.css';

const Welcome = () => {
    const { locale } = useContext(LocaleContext);
    const [ currentSlide, setCurrentSlide ] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            setCurrentSlide(currentSlide < 3 ? currentSlide + 1 : 1);
        }, 5000);
    }, [currentSlide]);

    return (
        <div className="welcome max-h-full max-w-full bg-white relative z-30">
            <Helmet>
                <title>{Strings[locale].home.title}</title>
                <meta name="description" content={Strings[locale].home.description} />
            </Helmet>
            <div
                className={`${ currentSlide === 1 ? 'translate-x-0' : (currentSlide !== 3 ? '-translate-x-full' : 'translate-x-full') } welcome-slide-1 z-40 absolute inset-0 w-screen h-screen text-white bg-center bg-cover transition-all ease-in-out duration-1000 transform translate-x-0 slide`} />
            <div
                className={`${ currentSlide === 2 ? 'translate-x-0' : 'translate-x-full' } welcome-slide-2 z-40 absolute inset-0 w-screen h-screen bg-blue-500 text-white bg-center bg-cover transition-all ease-in-out duration-1000 transform translate-x-0 slide`} />
            <div
                className={`${ currentSlide === 3 ? 'translate-x-0' : '-translate-x-full' } welcome-slide-3 z-40 absolute inset-0 w-screen h-screen bg-orange-500 text-white bg-center bg-cover transition-all ease-in-out duration-1000 transform translate-x-0 slide`} />
        </div>
    );
};

export default Welcome;