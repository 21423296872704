import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Image1 from '../../assets/img/marriage.jpg';
import Image2 from '../../assets/img/glasses.jpg';
import Image3 from '../../assets/img/wedding.jpg';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';
import './HorizontalSlideshow.css';

const sliderImages = [
    Image1,
    Image2,
    Image3,
];

const HorizontalSlideshow = ({images}) => {
    const [translateXPosition, setTranslateXPosition] = useState(0);
    const [currentPositionImage, setCurrentPositionImage] = useState(0);
    const [maxTranslateX, setMaxTranslateX] = useState();
    const {locale} = useContext(LocaleContext);
    const imageRef = useRef();

    const onNextClick = useCallback(() => {
        if (maxTranslateX + translateXPosition !== (imageRef.current.clientWidth + 15)) {
            setTranslateXPosition((st => st - (imageRef.current.clientWidth + 15)));
            setCurrentPositionImage((st) => st+1);
        }
    }, [translateXPosition, maxTranslateX, setTranslateXPosition, setCurrentPositionImage]);
    
    const onLastClick = useCallback(() => {
        if (translateXPosition < 0) {
            setTranslateXPosition((st => st + (imageRef.current.clientWidth + 15)));
            setCurrentPositionImage((st) => st-1);
        }
    }, [translateXPosition]);

    useEffect(() => {
        if (imageRef && imageRef.current) {
            setMaxTranslateX((images || sliderImages).length * (imageRef.current.clientWidth + 15));
        }
        // eslint-disable-next-line
    }, [imageRef])

    return (
        <div className="relative w-full xl:pb-10">
            <div className="slider__main-image-container w-full relative">
                <p
                    className="next-label absolute font-extrabold text-xs lg:text-sm uppercase cursor-pointer"
                    onClick={onNextClick}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                        {Strings[locale].components.horizontalSlideshow.slideNextImageLabel}
                </p>
                <p
                    className="before-label absolute uppercase font-extrabold text-xs lg:text-sm cursor-pointer"
                    onClick={onLastClick}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                        {Strings[locale].components.horizontalSlideshow.slidePreviousImageLabel}
                </p>
                <div className="slider__main-image" style={{backgroundImage: `url('${(images || sliderImages)[currentPositionImage]}')`}} />
                {/* <img src={(images || sliderImages)[currentPositionImage]} alt="Selected image" className="object-cover current-slider-image" /> */}
            </div>
            <div className="images-slider absolute bottom-0 right-0 -mr-64 mb-16 overflow-x-hidden">
                <div className="flex transition-all duration-500 ease-in-out" style={{transform: `translateX(${translateXPosition}px)`}}>
                    {(images || sliderImages).map((image, i) => <div
                        key={`image${i}`}
                        ref={imageRef}
                        className="shadow cursor-pointer thumb-image"
                        style={{marginRight: '15px', backgroundImage: `url('${image}')`}}
                        onClick={() => setCurrentPositionImage(i)}
                    />)}
                </div>
            </div>
        </div>
    );
};

export default HorizontalSlideshow;