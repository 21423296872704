import React, { useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Image1 from '../../assets/img/marriage.jpg';
import Image2 from '../../assets/img/glasses.jpg';
import Image3 from '../../assets/img/wedding.jpg';
import FiguraBg from '../../assets/img/figura_ce.png';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';
import { Helmet } from 'react-helmet';

import {
    HorizontalSlideshow
} from '../../components';

const items = [
    {name: 'Corporativos', hash: '#corporate'},
    {name: 'Trade shows', hash: '#tradeshows'},
    {name: 'Lanzamiento de productos', hash: '#products'},
    {name: 'Diseño y producción', hash: '#design'},
];

const Events = ({location, history}) => {
    const { locale } = useContext(LocaleContext);

    return (
        <div
            className="w-screen min-h-screen gap-8 flex flex-col md:flex-row items-start relative">
            <Helmet>
                <title>{Strings[locale].events.title}</title>
                <meta name="description" content={Strings[locale].events.description} />
            </Helmet>
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:w-1/6 px-6">
                {Strings[locale].events.eventTypes.map((item, index) => <div key={`events${index}`} className="w-full mb-2">
                    <div
                        onClick={() => history.push(`/${locale}/events${item.hash}`)}
                        className={`${location.hash === item.hash ? 'font-bold' : ''} title flex items-center cursor-pointer`}>
                        <h4 className={`${location.hash !== item.hash ? 'font-semibold lg:text-xl' : 'font-extrabold text-lg lg:text-2xl'} uppercase text-gray-900`}>{item.title}</h4>
                        <FontAwesomeIcon icon={faChevronRight} className={`${location.hash === item.hash && 'transform rotate-90 ml-12'} ml-4 transition-all ease-in-out duration-200`} />
                    </div>
                    <div className={`${location.hash !== item.hash ? 'hidden' : ''} content transition-all ease-in-out duration-1000`}>
                        <p className="text-xs mt-2">{item.text}</p>
                        <button className="mb-2 border-2 border-gray-900 text-gray-900 w-full py-2 lg:py-3 uppercase mt-4 font-extrabold text-xs lg:text-sm" onClick={() => history.push(`/${locale}/contact`)}>
                            {Strings[locale].generic.eventRequest}
                        </button>
                    </div>
                </div>)}
            </div>
            <div className="w-full md:w-3/4 xl:w-5/6">
                <div className="slide-container md:hidden">
                    <Slide>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image1})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image2})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image3})`}} className="h-64 bg-cover" />
                        </div>
                    </Slide>
                </div>
                <div className="hidden md:block">
                    <HorizontalSlideshow />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Events);