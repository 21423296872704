import React, { useState, useContext } from 'react';
import { Slide } from 'react-slideshow-image';
import Image1 from '../../assets/img/marriage.jpg';
import Image2 from '../../assets/img/glasses.jpg';
import Image3 from '../../assets/img/wedding.jpg';
import Garden from '../../assets/img/venues/JARDIN.jpg';
import EventRoom from '../../assets/img/venues/SALON.jpg';
import Terrace from '../../assets/img/venues/TERRAZA.jpg';
import FiguraBg from '../../assets/img/figura_ce.png';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';
import { Helmet } from 'react-helmet';

import {
    HorizontalSlideshow
} from '../../components';

const images = [
    [
       Image3,
       Image2,
       Image3,
    ],
    [
        Garden,
        Image2,
        Image3,
    ],
     [
        Terrace,
        Image2,
        Image3,
    ],
    [
        EventRoom,
        Image2,
        Image3,
    ]
]

const Venues = () => {
    const [ itemSelected, setItemSelected ] = useState(0);
    const { locale } = useContext(LocaleContext);

    return (
        <div
            className="w-screen min-h-screen flex gap-8 flex flex-col md:flex-row relative">
            <Helmet>
                <title>{Strings[locale].venues.title}</title>
                <meta name="description" content={Strings[locale].venues.description} />
            </Helmet>
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:w-1/6 px-6">
                {Strings[locale].venues.items.map((item, index) => <div key={index} className="w-full mb-2">
                    <div
                        onClick={() => setItemSelected(index)}
                        className={`${itemSelected !== index ? 'font-bold' : ''} title flex items-center cursor-pointer`}>
                        <h4 className={`${itemSelected !== index ? 'font-semibold lg:text-xl' : 'font-extrabold text-lg lg:text-2xl'} uppercase text-gray-900`}>{item.title}</h4>
                    </div>
                </div>)}
            </div>
            <div className="w-full md:w-3/4 xl:w-5/6 h-auto">
                <div className="slide-container md:hidden">
                    <Slide>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image1})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image2})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image3})`}} className="h-64 bg-cover" />
                        </div>
                    </Slide>
                </div>
                <div className="hidden md:block">
                    <HorizontalSlideshow images={images[itemSelected]} />
                </div>
            </div>
        </div>
    );
};

export default Venues;