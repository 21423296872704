import React, { useEffect, useRef, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FiguraBg from '../../assets/img/figura_ce.png';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";
import Strings from "../../translations/strings";
import { LocaleContext } from "../../context";
import { Helmet } from 'react-helmet';

const formSchema = yup.object().shape({
    name: yup.string().required(),
    commercialName: yup.string().required(),
    phone: yup.string(),
    email: yup.string().required(),
    service: yup.string(),
    budget: yup.string(),
    people: yup.number(),
    date: yup.string(),
    place: yup.string(),
    about: yup.string()
});

const Contact = () => {
    const [ formHeight, setFormHeight ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showConfirmationMsg, setShowConfirmationMsg ] = useState(false);
    const formContainer = useRef(null);
    const { register, handleSubmit, errors, reset } = useForm({resolver: yupResolver(formSchema), mode: 'all'});
    const { locale } = useContext(LocaleContext);

    const onSubmit = (data) => {
        setIsLoading(true);
        axios.post(`https://collection-back.herokuapp.com/contact-email`, data)
            .then(response => {
                console.log(response);
                setIsLoading(false);
                setShowConfirmationMsg(true);
                reset();
                setTimeout(() => {
                    setShowConfirmationMsg(false);
                }, 5000);
            }).catch(error => {
                console.error(error);
                setIsLoading(false);
            })
    };

    const getHeight = () => formContainer?.current?.offsetHeight || 597;

    useEffect(() => {
        setFormHeight(getHeight());
    }, [formContainer]);

    return (
        <div
            className="w-screen min-h-screen block md:flex px-6 gap-8 relative" style={{marginBottom: '20px'}}>
            <Helmet>
                <title>{Strings[locale].contact.title}</title>
                <meta name="description" content={Strings[locale].contact.description} />
            </Helmet>
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:w-1/6">
                <ul className="text-center md:text-left w-full">
                    <li className="uppercase font-bold">{Strings[locale].contact.emailText}</li>
                    <li className="mt-1 break-all">contact@collectioneventmexico.com</li>
                </ul>
                
                <ul className="mt-8 text-center md:text-left">
                    <li className="uppercase font-bold">{Strings[locale].contact.presenceIn}</li>
                    <li className="mt-1">Los Cabos</li>
                    <li className="mt-1">Cancún</li>
                    <li className="mt-1">Guadalajara</li>
                    <li className="mt-1">Cd. De México</li>
                    <li className="mt-1">Hermosillo</li>
                </ul>
                <ul className="mt-2 text-center md:text-left">
                    <li className="uppercase font-bold">{Strings[locale].contact.phones}</li>
                    <li className="mt-1">+52 (662) 194 5373</li>
                </ul>

                <div className="mt-8 text-center md:text-left">
                    <p className="uppercase font-bold">{Strings[locale].contact.followUs}</p>
                    <div className="flex justify-center md:justify-start text-xl mt-2">
                        <a href="https://www.facebook.com/CollectionEventsMexico" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} className="cursor-pointer" />
                        </a>
                        <a href="https://www.instagram.com/collectioneventsmexico/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="ml-8 cursor-pointer" />
                        </a>
                        {/* <a href="https://www.instagram.com/collectioneventsmexico/" target="_blank">
                            <FontAwesomeIcon icon={faYoutube} className="ml-8 cursor-pointer" />
                        </a> */}
                    </div>
                    <p className="mt-2">@collection_events</p>
                </div>
            </div>
            <div className="w-full md:w-3/4 xl:w-5/6 pt-6 md:pt-0 pr-0 md:pr-10 z-40">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full rounded block md:flex"
                    style={{boxShadow: '0px 0px 13px 1px rgba(0,0,0,0.75)'}}>
                    <div className="w-full md:w-4/6 md:border-r-1 border-bg-black" ref={formContainer}>
                        <div className="w-full block md:flex gap-4 border-b-1 border-gray-600 p-4">
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="name">{Strings[locale].contact.formFields.name}*</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="name"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                                <span className="text-xs text-red-500">{errors?.name?.message}</span>
                            </div>
                            <div className="w-full md:w-1/2">
                                <label className="text-sm font-bold" htmlFor="alias">{Strings[locale].contact.formFields.commercialName}*</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="commercialName"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                                <span className="text-xs text-red-500">{errors?.commercialName?.message}</span>
                            </div>
                        </div>
                        <div className="w-full block md:flex gap-4 border-b-1 border-gray-600 p-4">
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="phone">{Strings[locale].contact.formFields.phone}</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="phone"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="email">{Strings[locale].contact.formFields.email}*</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="email"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                                <span className="text-xs text-red-500">{errors?.email?.message}</span>
                            </div>
                        </div>
                        <div className="w-full block md:flex gap-4 border-b-1 border-gray-600 p-4">
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="service">{Strings[locale].contact.formFields.service}</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="service"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="date">{Strings[locale].contact.formFields.date}</label>
                                <input
                                    ref={register}
                                    type="date"
                                    name="date"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div className="w-full block md:flex gap-4 border-b-1 border-gray-600 p-4">
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="people">{Strings[locale].contact.formFields.people}</label>
                                <input
                                    ref={register}
                                    type="number"
                                    name="people"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                            <div className="w-full md:w-1/2 mb-2 md:mb-0">
                                <label className="text-sm font-bold" htmlFor="budget">{Strings[locale].contact.formFields.budget}</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="budget"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div className="w-full flex gap-4 p-4 md:pb-20 md:border-b-1 border-gray-600">
                            <div className="w-full">
                                <label className="text-sm font-bold" htmlFor="place">{Strings[locale].contact.formFields.place}</label>
                                <input
                                    ref={register}
                                    type="text"
                                    name="place"
                                    className="border-1 border-gray-600 mt-2 w-full p-2 focus:outline-none"
                                    autoComplete="off"/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/6 p-4 gap-y-10" style={{height: `${formHeight}px`}}>
                        <div className="w-full h-5/6">
                            <label className="text-sm font-bold h-2/12" htmlFor="address">{Strings[locale].contact.formFields.about}</label>
                            <textarea
                                ref={register}
                                type="text"
                                name="about"
                                className="border-1 border-gray-600 mt-2 w-full h-11/12 p-2 focus:outline-none"
                                autoComplete="off"
                                style={{resize: 'none'}}/>
                        </div>
                        <div className="w-full focus:outline-none h-1/6">
                            {!showConfirmationMsg ? <button type="submit" className="h-full bg-black text-white w-full focus:outline-none flex justify-center items-center" disabled={isLoading}>
                                {isLoading ? <><BounceLoader color="#118a81" loading={isLoading} size={60} /></> : Strings[locale].contact.formFields.submitButton}
                            </button> :
                            <div className="w-full h-full bg-white rounded-md flex justify-center items-center p-2 text-center" style={{color: '#118a81'}}>{Strings[locale].contact.formFields.successMsg}</div>}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;