import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import Ceremony1 from '../../assets/img/weddings/ceremony/wedding-1.jpg';
import Ceremony2 from '../../assets/img/weddings/ceremony/wedding-2.jpg';
import Ceremony3 from '../../assets/img/weddings/ceremony/wedding-3.jpg';
import Egalitarian1 from '../../assets/img/weddings/egalitarian/egalitarian-1.jpg';
import Egalitarian2 from '../../assets/img/weddings/egalitarian/egalitarian-2.jpg';
import Egalitarian3 from '../../assets/img/weddings/egalitarian/egalitarian-3.jpg';
import Jewish1 from '../../assets/img/weddings/jewish/jewish-1.jpg';
import Jewish2 from '../../assets/img/weddings/jewish/jewish-2.jpg';
import Jewish3 from '../../assets/img/weddings/jewish/jewish-3.jpg';
import Legal1 from '../../assets/img/weddings/legal/legal-1.jpg';
import Legal2 from '../../assets/img/weddings/legal/legal-2.jpg';
import Legal3 from '../../assets/img/weddings/legal/legal-3.jpg';
import FiguraBg from '../../assets/img/figura_ce.png';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';

import {
    HorizontalSlideshow
} from '../../components';

const images = {
    ceremony: [
        Ceremony1,
        Ceremony2,
        Ceremony3,
    ],
    egalitarian: [
        Egalitarian1,
        Egalitarian2,
        Egalitarian3
    ],
    jewish: [
        Jewish1,
        Jewish2,
        Jewish3
    ],
    legal: [
        Legal1,
        Legal2,
        Legal3
    ]
}

const imagesKeys = [
    'ceremony',
    'legal',
    'jewish',
    'egalitarian'
];

const Weddings = () => {
    const [ itemSelected, setItemSelected ] = useState(0);
    const { locale } = useContext(LocaleContext);
    const [ weddingSelected, setWeddingSelected ] = useState('ceremony');
    const history = useHistory();

    const onWeddingTypeClick = (index) => {
        setItemSelected((st) => index)
        setWeddingSelected((st) => imagesKeys[index]);
    };

    return (
        <div
            className="w-screen min-h-screen flex gap-8 flex flex-col md:flex-row relative">
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:1/6 px-6">
                {Strings[locale].weddings.listItems.map((item, index) => <div key={index} className="w-full mb-2">
                    <div
                        onClick={() => onWeddingTypeClick(index)}
                        className={`${itemSelected !== index ? 'font-bold' : ''} title flex items-center cursor-pointer`}>
                        <h4 className={`${itemSelected !== index ? 'font-semibold lg:text-xl' : 'font-extrabold text-lg lg:text-2xl'} uppercase text-gray-900`}>{item}</h4>
                    </div>
                </div>)}
                <button className="border-2 border-gray-900 text-gray-900 w-full py-2 lg:py-3 uppercase mt-4 font-extrabold text-xs lg:text-sm" onClick={() => history.push(`/${locale}/contact`)}>
                    {Strings[locale].generic.eventRequest}
                </button>
            </div>
            <div className="w-full md:w-3/4 xl:w-5/6 h-auto">
                <div className="slide-container md:hidden">
                    <Slide>
                        {images[weddingSelected].map((image, index) => 
                            <div key={`image${index}`} className="each-slide">
                                <div style={{'backgroundImage': `url(${image})`}} className="h-64 bg-cover" />
                            </div>)}
                    </Slide>
                </div>
                <div className="hidden md:block">
                    <HorizontalSlideshow images={images[weddingSelected]} />
                </div>
            </div>
        </div>
    );
};

export default Weddings;