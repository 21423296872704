import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import Image1 from '../../assets/img/marriage.jpg';
import Image2 from '../../assets/img/glasses.jpg';
import Image3 from '../../assets/img/wedding.jpg';
import Audiovisual from '../../assets/img/services/AUDIOVISUAL.jpg';
import Catering from '../../assets/img/services/BANQUETES.jpg';
import Flowers from '../../assets/img/services/FLORES.jpg';
import MakeUp from '../../assets/img/services/MAKEUP.jpg';
import Souvernirs from '../../assets/img/services/SOUVENIRS.jpg';
import Staff from '../../assets/img/services/STAFF.jpg';
import Transportation from '../../assets/img/services/TRANSPORTE.jpg';
import FiguraBg from '../../assets/img/figura_ce.png';
import { LocaleContext } from '../../context';
import Strings from '../../translations/strings';

import {
    HorizontalSlideshow
} from '../../components';

const images = {
    audiovisual: [
        Audiovisual,
        Image2,
        Image3,
    ],
    furniture: [
        Image1,
        Image2,
        Image3,
    ],
    flowers: [
        Flowers,
        Image2,
        Image3,
    ],
    catering: [
        Catering,
        Image2,
        Image3,
    ],
    lodging: [
        Image1,
        Image2,
        Image3,
    ],
    transportation: [
        Transportation,
        Image2,
        Image3,
    ],
    makeup: [
        MakeUp,
        Image2,
        Image3,
    ],
    staff: [
        Staff,
        Image2,
        Image3,
    ],
    souvenirs: [
        Souvernirs,
        Image2,
        Image3,
    ],
    furnishings: [
        Image1,
        Image2,
        Image3,
    ],
}

const Services = ({location, history}) => {
    const { locale } = useContext(LocaleContext);

    return (
        <div
            className="w-screen min-h-screen flex gap-8 flex flex-col md:flex-row">
            <img src={FiguraBg} alt="Figura" className="absolute bottom-0 right-0" style={{width: '45%'}} />
            <div className="w-full md:w-1/4 xl:w-1/6 px-6">
                {Strings[locale].services.services.map((item, index) => <div key={index} className="w-full mb-2">
                    <div
                        onClick={() => history.push(`/${locale}/services${item.hash}`)}
                        className={`${location.hash === item.hash ? 'font-bold' : ''} title flex items-center cursor-pointer`}>
                        <h4 className={`${location.hash !== item.hash ? 'font-semibold lg:text-xl' : 'font-extrabold text-lg lg:text-2xl'} uppercase text-gray-900`}>{item.title}</h4>
                    </div>
                </div>)}
            </div>
            <div className="w-full md:w-3/4 xl:w-5/6">
                <div className="slide-container md:hidden">
                    <Slide>
                        {(images[location?.hash?.replace('#', '') || 'furnishings']).map((image) => <div className="each-slide">
                            <div style={{'backgroundImage': `url(${image})`}} className="h-64 bg-cover" />
                        </div>)}
                        {/* <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image2})`}} className="h-64 bg-cover" />
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${Image3})`}} className="h-64 bg-cover" />
                        </div> */}
                    </Slide>
                </div>
                <div className="hidden md:block">
                    <HorizontalSlideshow images={images[location?.hash?.replace('#', '') || 'furnishings']} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Services);