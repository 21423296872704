import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { LocaleContext } from './context';
import { isValidLocale } from './translations';

import {
  Welcome,
  About,
  Events,
  Services,
  Weddings,
  Venues,
  Contact
} from './pages';

import './assets/styles/tailwind.css';

function App({location, history}) {
  const { setLocale } = useContext(LocaleContext);
  const routeSplited = location.pathname.split('/');
  const locale = routeSplited[1];

  useEffect(() => {
    if (isValidLocale(locale)) {
      setLocale(locale);
    } else {
      history.push('/es');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App min-h-screen min-w-screen">
      <Switch>
        <Route exact path="/:lang" component={Welcome} />
        <Route exact path="/:lang/about" component={About} />
        <Route exact path="/:lang/events" component={Events} />
        <Route exact path="/:lang/services" component={Services} />
        <Route exact path="/:lang/weddings" component={Weddings} />
        <Route exact path="/:lang/venues" component={Venues} />
        <Route exact path="/:lang/contact" component={Contact} />
        <Redirect to="/es" />
      </Switch>
    </div>
  );
}

export default withRouter(App);
