import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/logo.png';
import BackgroundImage from '../../assets/img/background.png';
import String from '../../translations/strings';
import { LangToggle } from '../../components';

const MobileNavbar = ({locale, setIsMenuOpen, onMenuItemClick, currentSection, onLangToggleChange}) => {
    const [ submenuOpen, setSubmenuOpen ] = useState();
    
    return (
        <nav className="min-h-screen w-screen bg-white absolute z-70 flex md:hidden flex-col items-center py-2" style={{backgroundImage: `url('${BackgroundImage}')`}}>
            <div className="flex justify-between items-center w-full px-2">
                <Link to={`/${locale}`}>
                    <img
                        src={Logo}
                        alt="White logo"
                        width={236}
                        height={90} />
                </Link>

                <div className={`text-green-700`}>
                    <Hamburger toggled={true} toggle={setIsMenuOpen} />
                </div>
            </div>
            <ul className="w-full text-black text-2xl mt-2 px-6">
                <li className={`${currentSection === 'about' ? 'font-bold' : ''} px-4 py-4 w-full text-center border-b-1 border-gray-500`} onClick={() => onMenuItemClick(`/${locale}/about`)}>
                    {String[locale].about.menuText}
                </li>
                <li className={`${currentSection === 'weddings' ? 'font-bold' : ''} px-4 py-4 w-full text-center border-b-1 border-gray-500`} onClick={() => onMenuItemClick(`/${locale}/weddings`)}>
                    {String[locale].weddings.menuText}
                </li>
                <li
                    onClick={() => setSubmenuOpen('events')}
                    className="px-4 py-4 w-full text-center border-b-1 border-gray-500">
                    <div className="flex justify-center items-center">
                        <p className={currentSection === 'events' ? 'font-bold' : ''}>{String[locale].events.menuText}</p>
                        <FontAwesomeIcon icon={submenuOpen === 'events' ? faChevronDown : faChevronRight} className="ml-2 text-sm" />
                    </div>
                    {submenuOpen === 'events' && <ul className="bg-white hover-target text-black text-lg mt-2">
                        {String[locale].events.menuSubitems.map((item, index) =>
                            <li key={`event${index}`} className="py-2 hover:text-green-500" onClick={() => onMenuItemClick(item.url.replace('[locale]', locale))}>
                                {item.text}
                            </li>)}
                    </ul>}
                </li>
                <li
                    onClick={() => setSubmenuOpen('services')}
                    className="px-4 py-4 w-full text-center border-b-1 border-gray-500">
                    <div className="flex justify-center items-center">
                        <p className={currentSection === 'services' ? 'font-bold' : ''}>{String[locale].services.menuText}</p>
                        <FontAwesomeIcon icon={submenuOpen === 'services' ? faChevronDown : faChevronRight} className="ml-2 text-sm" />
                    </div>
                    {submenuOpen === 'services' && <ul className="bg-white hover-target text-black text-lg mt-2">
                        {String[locale].services.menuSubitems.map((item, index) =>
                            <li key={`service${index}`} className="py-2 hover:text-green-500" onClick={() => onMenuItemClick(item.url.replace('[locale]', locale))}>
                                {item.text}
                            </li>)}
                    </ul>}
                </li>
                <li className={`${currentSection === 'venues' ? 'font-bold' : ''} px-4 py-4 w-full text-center border-b-1 border-gray-500`} onClick={() => onMenuItemClick(`/${locale}/venues`)}>
                    {String[locale].venues.menuText}
                </li>
                <li className={`${currentSection === 'contact' ? 'font-bold' : ''} px-4 py-4 w-full text-center border-b-1 border-gray-500`} onClick={() => onMenuItemClick(`/${locale}/contact`)}>
                    {String[locale].contact.menuText}
                </li>
                <li className={`pt-8 w-full text-center border-gray-500 flex justify-center`}>
                    <LangToggle value={locale} onValueChange={onLangToggleChange} />
                </li>
            </ul>
        </nav>
    );
};

export default MobileNavbar;